import contactTexts from '../utils/Texts/contactTexts.json'
import { useLangContext } from '../../Context/LangContext'
import errorTexts from '../utils/Texts/errorTexts.json'
import { Validation } from '../utils/validation'
import emailjs from 'emailjs-com'
import './modal.css'
import CustomAlert from './CustomAlert'
import { useState } from 'react'

const ModalComplains = ({
  modalInfo,
  toggleModal,
  setModalInfo,
  errors,
  setErrors
}) => {
  const { selectedLanguage } = useLangContext()
  const text = contactTexts[selectedLanguage]
  const errorMessages = errorTexts[selectedLanguage]
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('')

  const handleModal = (
    e,
    name,
    type,
    required = false,
    maxLength = false,
    minLength = false
  ) => {
    const infoInput = modalInfo
    const { changedInfoInput, value, err } = Validation(
      e,
      name,
      type,
      required,
      maxLength,
      minLength,
      infoInput,
      errors,
      errorMessages
    )
    setErrors(err)
    setModalInfo({ ...changedInfoInput, [e.target.name]: value })
  }
  const isFormValid =
    modalInfo.email.trim() !== '' &&
    modalInfo.message.trim() !== '' &&
    !errors.email &&
    !errors.message

  const handleSendEmail = () => {
    const { email, message } = modalInfo

    const templateParams = {
      from_email: email,
      message: message
    }

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(() => {
        setAlertMessage('Message sent successfully!')
        setAlertType('success')
        setShowAlert(true)

        setTimeout(() => {
          setShowAlert(false)
          toggleModal()
        }, 3000) // 3 segundos de duración

        setModalInfo({ email: '', message: '' })
        setErrors({})
      })
      .catch(error => {
        console.error('FAILED...', error)
        setAlertMessage('Failed to send message. Please try again.')
        setAlertType('error')
        setShowAlert(true)

        setTimeout(() => {
          setShowAlert(false)
        }, 3000) // 3 segundos de duración
      })
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>
          {text.reportTitle}
        </h2>
        <p className="complain-text">
          {text.reportText1}
        </p>
        <p className="complain-text">
          {text.reportText2}
          <span>
            {text.reportMail}
          </span>
          {text.reportText3}
        </p>
        <div className="modal-form">
          <label className="label-black">Email</label>
          <input
            name="email"
            className="modal-input"
            value={modalInfo.email}
            type="email"
            onChange={e => handleModal(e, 'email', 'email', true, 75, 8)}
            placeholder="Ingresá tu mail"
            required={true}
          />
          {errors['email'] &&
            <p className="error-modal">
              {errors['email']}
            </p>}

          <label className="label-black">
            {text.formTexts.message}
          </label>
          <textarea
            name="message"
            className="modal-textarea"
            value={modalInfo.message}
            type="text"
            onChange={e => handleModal(e, 'message', 'text', true, 250, 3)}
            placeholder={text.placeholders.message}
            required={true}
          />
          {errors['message'] &&
            <p className="error-modal">
              {errors['message']}
            </p>}
        </div>
        <div className="modal-buttons-box">
          <button className="modal-button-secondary" onClick={toggleModal}>
            {text.cancelButton}
          </button>
          <button
            className="modal-button"
            onClick={() => {
              handleSendEmail()
            }}
            disabled={!isFormValid}
          >
            {text.sendButton}
          </button>
        </div>
      </div>
      <CustomAlert show={showAlert} message={alertMessage} type={alertType} />
    </div>
  )
}

export default ModalComplains
