import './customAlert.css'
const CustomAlert = ({ show, message, type }) => {
  if (!show) return null

  return (
    <div className={`custom-alert alert-${type}`}>
      <p>
        {message}
      </p>
    </div>
  )
}

export default CustomAlert
