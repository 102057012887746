import React from 'react'
import { Icon } from '@iconify/react'
import { useState } from 'react';
import './landing.css'
import BigButton from '../utils/BigButton/BigButton'
import { Link } from 'react-router-dom'

const ProductTitleMobile = ({title, text, id, subtitle, name}) => {
        const [isActive, setActive] = useState("false");
    
    const handleToggle = () => {
      setActive(!isActive);
     
    }
  return (
    <>
    <div className='title-box-mobile'>
            <div className='product-titles-mobile'>
                <p className='product-subtitle'>{subtitle}</p>
                <h5 className='product-title-mobile'>{title}</h5>
            </div>
          
            <Icon icon="material-symbols:keyboard-arrow-down-rounded" className=  {isActive ? 'down-arrow-black down' : "rotate down"} onClick={handleToggle}/>
           
            </div>
             <div className={isActive ? "display-none" : 'product-mobile-text'}>
            <p>{text}</p>
            <div className={id===5? "button-section":"display-none"}> <Link to="https://widgets.winse.online/rental-guarantee/pk-0f1a9e19-4326-4388-af23-d7d95079557e/" target="_blank" rel="noreferrer"><BigButton className="light-button" name={name} /></Link></div>
            <div className={id===6? "button-section":"display-none"} ><Link to='/cotizador-directores'><BigButton className="light-button" name={name} /></Link></div>
            </div>
            </>
  )
}

export default ProductTitleMobile

